import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

import AuthContext from '../context/AuthContext';
import PostInfo from './PostInfo';
import FetchedPost from './FetchedPost';
import Modal from './Modal';

import { getPosts, createNewPost } from '../actions/posts';
import { searchSource, findMissingInfo } from '../actions/sources';


// Use unique API link to identify whether the artwork has already been featured on Peerdiem
const PostDuplicate = ({ posts, selection }) => {
  
  const postIsDuplicate = posts.some(post => post.api_link === selection.api_link);

  return (
    <div class="my-2">
      <span class={`mb-2 p-2 text-base rounded-md ${postIsDuplicate ? 'text-red-600 bg-red-200' : 'text-green-600 bg-green-200'}`}>{postIsDuplicate ? 'duplicate' : 'new'}</span>
    </div>
  )
}

// List all the missing information for the selected artwork
// Usually happens when the global search API endpoint used does not return all the info needed
// Missing info will be added once the post is created
const PostMissingInfo = ({ selection, handleFindMissingInfo }) => {
  
  return (
    <div class="my-2">

      {/* List of missing post fields */}
      <p>Missing information</p>

      <p class="italic">Mandatory</p>
      <div class="flex flex-wrap">
        {!selection.api_link && <span class="m-1 p-2 text-base rounded-md text-red-600 bg-red-200">api_link</span>}
        {!selection.image_url && <span class="m-1 p-2 text-base rounded-md text-red-600 bg-red-200">image_url</span>}
        {!selection.image_id && <span class="m-1 p-2 text-base rounded-md text-red-600 bg-red-200">image_id</span>}
        {!selection.source && <span class="m-1 p-2 text-base rounded-md text-red-600 bg-red-200">source</span>}
        {!selection.resource_id && <span class="m-1 p-2 text-base rounded-md text-red-600 bg-red-200">resource_id</span>}
      </div>

      <p class="italic">Optional</p>
      <div class="flex flex-wrap">
        {!selection.title && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">title</span>}
        {!selection.artist_title && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">artist_title</span>}
        {!selection.medium_display && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">medium_display</span>}
        {!selection.date_start && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">date_start</span>}
        {!selection.date_end && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">date_end</span>}
        {!selection.department_title && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">department_title</span>}
        {!selection.collection && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">collection</span>}
        {!selection.style && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">style</span>}
        {!selection.genre && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">genre</span>}
        {!selection.width && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">width</span>}
        {!selection.height && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">height</span>}
        {!selection.copyright_notice && <span class="m-1 p-2 text-base rounded-md text-yellow-600 bg-yellow-200">copyright_notice</span>}
        
      </div>

        {/* Call endpoint to fill missing fields */}
        {
          (!selection.title ||
          !selection.source ||
          !selection.resource_id ||
          !selection.api_link ||
          !selection.artist_title ||
          !selection.date_start ||
          !selection.date_end ||
          !selection.medium_display ||
          !selection.image_url ||
          !selection.image_id ||
          !selection.department_title ||
          !selection.copyright_notice) 
          && (
          <div>
            <button type="button" onClick={handleFindMissingInfo} class="mt-1 mx-auto py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
              Find missing info
            </button>
          </div>
          )
        }

    </div>
  )
}

// 
const AdminPage = () => {

  const sources = [
    { 'id': 'artic', 'name': 'Art Institute of Chicago' },
    { 'id': 'cma', 'name': 'Cleveland Museum of Art' },
    { 'id': 'met', 'name': 'Metropolitan Museum of Art' },
    { 'id': 'rijks', 'name': 'Rijksmuseum' },
    { 'id': 'wikiart1', 'name': 'WikiArt V1' }
  ]

  const { state } = useContext(AuthContext);

  const [ posts, setPosts ] = useState([]);
  const [ previewedPost, setPreviewedPost ] = useState({});
  const [ submitDates, setSubmitDates ] = useState([]);
  const [ nextThirtyDay, setNextThirtyDay ] = useState([]);

  const defaultInputs = {
    source: '',
    query: '',
    limit: 12,
    search_type: 'keyword'
  }
  const [ inputs, setInputs] = useState(defaultInputs);
  
  const [ apiResults, setAPIResults ] = useState([]);
  const [ selectedPost, setSelectedPost ] = useState({});
  const [ submissionDate, setSubmissionDate ] = useState(new Date(Date.now()).toISOString().split('T')[0]);

  const [ distplayEditModal, setDisplayEditModal ] = useState(0);
  const [ feedbackMsg, setFeedbackMsg ] = useState('');
  const [ error, setError ] = useState('');

  useEffect(() => {

    // DEBUG: Avoid fetch API
    // setSelectedPost({
    //   "title": "A seashore",
    //   "source": "wikiart1",
    //   "resource_id": "190460",
    //   "api_link": "https://www.wikiart.org/en/App/Painting/ImageJson/190460",
    //   "artist_title": "Paul Gauguin",
    //   "date_start": "1887",
    //   "date_end": "1887",
    //   "medium_display": null,
    //   "image_url": "https://uploads8.wikiart.org/images/paul-gauguin/a-seashore-1887.jpg!Large.jpg",
    //   "low_res_image_url": null,
    //   "image_id": "190460",
    //   "department_title": null,
    //   "copyright_notice": null,
    //   "collection": null,
    //   "style": null,
    //   "genre": null,
    //   "width": null,
    //   "height": null
    // })

    // Fetch all posts to find the upcoming ones
    fetchAllPosts();

    // Get next 30 days
    let nextThirtyDaysArr = []
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth();
    let date = today.getDate();

    for (let i = 0; i < 30; i++) {
      let day = new Date(year, month, date + i);
      nextThirtyDaysArr.push(day);
    }

    setNextThirtyDay(nextThirtyDaysArr);

  }, [])

  // Fetch all posts
  const fetchAllPosts = async () => {
    try {

      const result = await getPosts(state.token);

      if (result.status === 'success') {
        setPosts(result.posts);
        let submitArr = result.posts.map(post => post.media_name.split('.')[0]);
        setSubmitDates(submitArr);
      } else {
        alert(result.detail || 'No error');
      }
    } catch (err) {
      alert(err.message);
    }
  }

  // Handle input changes
  const handleChange = (event) => {

    let name = event.target.name;
    let value = event.target.value;

    // Format the query to pass
    // if (name === 'query') {
    //   value = value.replace(/ /g, '+');
    // }

    setInputs(prevState => ({
      ...prevState,
      [name]: value
    }))

  }

  // Change submission date
  const handleSetSubmissionDate = (event) => {
    let date = event.target.value;
    setSubmissionDate(date);
  }

  // Search sources to find new artworks
  const handleSubmitRequest = async (event) => {

    event.preventDefault();

    if (inputs.source === '') {
      alert('Select a source to search new artworks');
      return false
    }

    try {

      const response = await searchSource(inputs, state.token);

      if (response.status === 'success') {
        setAPIResults(response.data);
        setFeedbackMsg(`Results: ${response.results_count} artworks found. Limit set to ${inputs.limit} items.`);
      } else {
        setError(json.message);
      }
    } catch (e) {
      return e.message;
    }

  }

  // Preview upcoming posts
  const handlePreviewPost = (date) => {
    let previewedPost = posts.filter(post => post.media_name.split('.')[0] === date)[0];
    previewedPost ? setPreviewedPost(previewedPost) : setPreviewedPost({});
  }

  // Find missing info of an artwork before inserting to database
  const handleFindMissingInfo = async (event) => {

    if (Object.keys(selectedPost).length !== 0) {
      
      try {

        const response = await findMissingInfo(selectedPost, state.token);
  
        if (response.status === 'success') {
          setSelectedPost(response.data);
        } else {
          setError(json.message);
        }
      } catch (e) {
        return e.message;
      }

    }

  }

  // Edit selected post
  const handleEditPost = () => {
    alert("TODO")
  }

  // Insert selected post to database
  const handleSubmitPost = async (event) => {

    event.preventDefault();

    if (Object.keys(selectedPost).length === 0) {
      alert('Select an post to insert');
      return false;
    } else if (submissionDate === '' || submissionDate < new Date(Date.now()).toISOString().split('T')[0]) {
      alert('Select a date either today or in the future');
      return false;
    }

    try {

      const result = await createNewPost(selectedPost, submissionDate, state.token);

      if (result.status === 'success') {
        // Update calendar of upcoming posts
        setSubmitDates([
          submissionDate,
          ...submitDates
        ]);
        alert(result.message);
      } else {
        alert(result.detail || 'Undefined error');
      }
    } catch (err) {
      alert(err.message);
    }

  }

  return (
    <div>

      <div class="h-full flex justify-between items-stretch space-x-4">

        <div class="flex flex-col items-stretch w-1/3 space-y-4">

          {/* API REQUESTS */}
          <div>
            <p>Find new artworks</p>
            <div class="p-6 bg-white border-2 border-grey-500 space-y-8">

              <div>
                <label class="text-gray-700" for="source">
                  Sources
                  <select onInput={handleChange} name="source" class="block rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent">
                    <option value="">
                      Select a source
                    </option>
                    {sources.map((api) => {
                      return (
                        <option key={api.id} value={api.id}>
                          {api.name}
                        </option>
                      )
                    })}
                  </select>
                </label>
              </div>

              <div>
                <label class="text-gray-700" for="searchKeyword">
                  Search query
                  <input type="text" name="query" onInput={handleChange} class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" autoComplete="off" placeholder="Your search"/>
                </label>
              </div>

              <div>
                <label class="text-gray-700" for="limit">
                  Results limit
                  <input type="number" name="limit" onInput={handleChange} defaultValue={inputs.limit} min="1" max="40" class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" autoComplete="off" />
                </label>
              </div>

              <div>
                <label class="text-gray-700" for="search_type">
                  Search Type
                  <select name="search_type" value={inputs.search_type} onChange={handleChange} class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" autoComplete="off">
                    <option value="keyword">Keywords</option>
                    <option value="random">Random</option>
                  </select>
                </label>
              </div>

              <div>
                <button type="button" onClick={handleSubmitRequest} class="block mx-auto py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                  Search
                </button>
              </div>

            </div>
          </div>

          {/* Posts scheduled for the next 30 days */}
          <div>
            <p>Upcoming posts</p>
            <div class="p-6 bg-white border-2 border-grey-500 space-y-8">

              <div class="flex flex-wrap justify-center">
                {
                  nextThirtyDay.length > 0 ? (
                    nextThirtyDay.map((date) => {

                      let day = ("0" + date.getDate()).slice(-2);
                      let month = ("0" + (date.getMonth() + 1)).slice(-2);
                      let year = date.getFullYear();
                      let fDate = `${year}-${month}-${day}`;

                      return (
                        <span 
                        class={`m-1 mb-2 p-2 text-base rounded-lg ${submitDates.includes(fDate) ? 'text-green-600 bg-green-200' : 'text-red-600 bg-red-200'} cursor-pointer`}
                        onClick={() => handlePreviewPost(fDate)}  
                        >
                          {day}/{month}
                        </span>
                      )
                    })
                  ) : (
                    <p class="text-center">There are no posts yet.</p>
                  )
                }
              </div>

            </div>
          </div>

          {/* Save post in database */}
          <div>
            <p>Save post</p>
            <div class="p-6 bg-white border-2 border-grey-500 space-y-8">

              <div>
                <label for="submissionDate">Submission date</label>
                <input type="date" onInput={handleSetSubmissionDate} name="submissionDate" defaultValue={submissionDate} class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" />
              </div>

              <div>
              <label>Selected post</label>
              {
                Object.keys(selectedPost).length ? (
                  <>
                    <PostDuplicate posts={posts} selection={selectedPost} />
                    <PostMissingInfo selection={selectedPost} handleFindMissingInfo={handleFindMissingInfo} />
                    <PostInfo post={selectedPost} isAdminView={true} />
                  </>
                ) : (
                  <p class="italic">No post selected</p>
                )
              }
              </div>

            </div>
          </div>

          <div class="flex flex-row">
            <button type="button" disabled={Object.keys(selectedPost).length === 0}  onClick={() => setDisplayEditModal(1)} class={`mx-auto py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ${Object.keys(selectedPost).length === 0 && 'opacity-50 cursor-not-allowed'}`}>
              Edit post
            </button>
            <button type="button" onClick={handleSubmitPost} class="mx-auto py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
              Submit post
            </button>
          </div>

        </div>

        <div class="container w-2/3 bg-white border-2 border-grey-500">

          {/* Error Message */}
          <div className={`${error.length === 0 ? 'hidden' : ''} shadow-md bg-yellow-100 border-yellow-600 text-yellow-500 border-l-4 p-4 m-4`} role="alert" >
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>

          {/* Feedback Message */}
          <div className={`${feedbackMsg.length === 0 ? 'hidden' : ''} shadow-md bg-green-100 border-green-600 text-green-500 border-l-4 p-4 m-4`} role="alert" >
            <p>{feedbackMsg}</p>
          </div>

          {
            apiResults.length > 0 ? (
              <div class="flex flex-wrap justify-around content-start items-center h-full overflow-x-auto">

                {
                  apiResults.map(post => (
                    <FetchedPost postData={post} postIsSelected={post.resource_id === selectedPost.resource_id} setSelectedPost={setSelectedPost} />
                  ))
                }

              </div>
            ) : (
              <div class="h-full flex justify-center items-center">
                {
                  Object.entries(previewedPost).length === 0 ? <p>Start by fetching API data</p> :
                  (
                    <div>
                      <img className="mx-auto" src={`${process.env.BACKEND_BASE_URL}/static/${previewedPost.media_name}`} style="max-height: 75vh"></img>
                      <PostInfo post={previewedPost} isWrapped={true} />
                    </div>
                  )
                }
              </div>
            )
          }
        </div>

      </div>

      {/* Display edit modal */}
      {
        (!!distplayEditModal && Object.keys(selectedPost).length !== 0) && <Modal selectedPost={selectedPost} setDisplayEditModal={setDisplayEditModal} setSelectedPost={setSelectedPost} />
      }      

    </div>
  )
}

export default AdminPage;
