import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { route } from 'preact-router';

import { startLogin, startResetPasswordEmail } from '../actions/authentication';
import AuthContext from '../context/AuthContext';


const LoginPage = ({ updateToggleVariable }) => {

  const { dispatch } = useContext(AuthContext);
  const [resetPassword, setResetPassword] = useState(false);

  const [feedback, setFeedback] = useState({
    feedbackHeader: "",
    feedbackMessage: ""
  });

  const login = async (event) => {

    try {
      event.preventDefault();
      const email = event.target.elements.email.value.trim();
      const password = event.target.elements.password.value.trim();

      resetFeedback();

      const result = await startLogin(email, password);

      if (result.type === 'LOGIN') {
        dispatch(result);
        route('/', true);
      } else {
        setFeedback(prevState => ({
          ...prevState,
          feedbackHeader: 'Error',
          feedbackMessage: result.msg || result.detail
        }));
        timeOutError();
      }
    } catch (error) {
      setFeedback(prevState => ({
        ...prevState,
        feedbackHeader: 'Error',
        feedbackMessage: error.message
      }));
      timeOutError();
    }
  }

  const handleResetPasswordEmail = async (event) => {

    try {
      
      event.preventDefault();
      const email = event.target.elements.email.value.trim();

      resetFeedback();

      const result = await startResetPasswordEmail(email);

      if (result.status === 'success') {
        setFeedback(prevState => ({
          ...prevState,
          feedbackHeader: 'Success',
          feedbackMessage: result.msg
        }));
        timeOutError();
      } else {
        setFeedback(prevState => ({
          ...prevState,
          feedbackHeader: 'Error',
          feedbackMessage: result.msg || result.detail
        }));
        timeOutError();
      }
    } catch (error) {
      setFeedback(prevState => ({
        ...prevState,
        feedbackHeader: 'Error',
        feedbackMessage: error.message
      }));
      timeOutError();
    }
  }

  const toggleResetVariable = () => {
    setResetPassword(!resetPassword);
  }

  const timeOutError = () => {
    setTimeout(() => resetFeedback(), 4000);
  }

  const resetFeedback = () => {
    setFeedback(prevState => ({
      ...prevState,
      feedbackHeader: '',
      feedbackMessage: ''
    }));
  }

  return (
    <div>

      <div className={`${feedback.feedbackHeader.length === 0 ? 'hidden' : ''} mx-auto w-full max-w-md ${feedback.feedbackHeader === 'Error' ? 'bg-pink-100 border-pink-600 text-pink-500' : 'bg-blue-100 border-blue-600 text-blue-500'} border-l-4 p-4 my-4`} role="alert">
        <p className="font-bold">{feedback.feedbackHeader}</p>
        <p>{feedback.feedbackMessage}</p>
      </div>

      <div className="mx-auto flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center text-md font-light text-gray-600 sm:text-2xl dark:text-white">
          {resetPassword ? 'Reset Password' : 'Login'}
        </div>
        <div className="mt-8">
          {
            resetPassword
              ? (
                // Form to reset password
                <form onSubmit={handleResetPasswordEmail} autoComplete="off">

                  {/* Email */}
                  <div className="flex flex-col mb-2">
                    <div className="flex relative ">
                      <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                        <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                          </path>
                        </svg>
                      </span>
                      <input type="text" name="email" placeholder="Your email" className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" />
                    </div>
                  </div>

                  <div className="flex w-full">
                    <button type="submit" className="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                      Reset
                    </button>
                  </div>

                </form>
              )
              : (

                // Login form
                <form onSubmit={login} autoComplete="off">

                  {/* Email */}
                  <div className="flex flex-col mb-2">
                    <div className="flex relative ">
                      <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                        <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                          </path>
                        </svg>
                      </span>
                      <input type="text" name="email" placeholder="Your email" className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" />
                    </div>
                  </div>

                  {/* Password */}
                  <div className="flex flex-col mb-6">
                    <div className="flex relative ">
                      <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                        <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                          </path>
                        </svg>
                      </span>
                      <input type="password" name="password" className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" placeholder="Your password" />
                    </div>
                  </div>

                  <div className="hidden flex items-center mb-6 -mt-4">
                    <div className="flex ml-auto">
                      <a href="#" className="inline-flex text-sm font-light text-gray-500 dark:text-gray-100 hover:text-gray-700 dark:hover:text-white">
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div className="flex w-full">
                    <button type="submit" className="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                      Login
                    </button>
                  </div>

                </form>

              )
          }
        </div>

        <div className="flex flex-col items-center justify-center mt-6">
          {!resetPassword && (
            <span onClick={updateToggleVariable} className="inline-flex items-center cursor-pointer text-sm font-light text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white">
              Don&#x27;t have an account? Sign up now
            </span>
          )}
          <span onClick={toggleResetVariable} className="inline-flex items-center cursor-pointer text-sm font-light text-center text-gray-500 hover:text-gray-700 dark:text-gray-100 dark:hover:text-white">
            {resetPassword ? 'Go back to login' : 'Forgot your password?'}
          </span>
        </div>

      </div>

    </div>
  );
}

export default LoginPage;
