import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

import AuthContext from '../context/AuthContext';
import PostInfo from './PostInfo';
import FetchedPost from './FetchedPost';

import { createNewPost } from '../actions/posts';
import { bulkSearchSources } from '../actions/sources';


// 
const AdminBulkPage = () => {

  const sources = [
    { 'id': 'artic', 'name': 'Art Institute of Chicago' },
    { 'id': 'cma', 'name': 'Cleveland Museum of Art' },
    { 'id': 'met', 'name': 'Metropolitan Museum of Art' },
    { 'id': 'rijks', 'name': 'Rijksmuseum' }
  ]

  const { state } = useContext(AuthContext);

  const [ suggestedPosts, setSuggestedPosts ] = useState([]);
  const [ selectedPosts, setSelectedPosts ] = useState([]);

  const defaultInputs = {
    source: { ...sources, selected: false },
    query: '',
    count: 30
  }
  const [ inputs, setInputs] = useState(defaultInputs);

  const [checkedState, setCheckedState] = useState(
    new Array(Object.keys(sources).length).fill(false)
  );
  
  const [ error, setError ] = useState('');

  // Handle input changes
  const handleChange = (event) => {

    let name = event.target.name;
    let value = event.target.value;

    setInputs(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  // Handle checkbox changes
  const handleCheckboxChange = (position, name) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    setInputs(prevState => ({
      ...prevState,
      source: {
        ...prevState.source,
        name
      }
    }))
  }

  // Search sources to find new artworks
  const handleSubmitRequest = async (event) => {

    event.preventDefault();

    if (inputs.source === '') {
      alert('Select a source to search new artworks');
      return false
    }

    try {

      const response = await bulkSearchSources(inputs, state.token);

      if (response.status === 'success') {
        setSuggestedPosts(response.data);
      } else {
        setError(json.message);
      }
    } catch (e) {
      return e.message;
    }

  }

  // Insert selected posts to database
  const handleSubmitPost = async (event) => {

    event.preventDefault();

    if (selectedPosts.length === 0) {
      alert('Select at least one post to add');
      return false;
    }

    try {

      const result = await createNewPosts(selectedPosts, state.token);

      if (result.status === 'success') {
        alert(result.message);
      } else {
        alert(result.detail || 'Undefined error');
      }
    } catch (err) {
      alert(err.message);
    }

  }

  return (
    <div>

      <div class="h-full flex justify-between items-stretch space-x-4">

        <div class="flex flex-col items-stretch w-1/3 space-y-4">

          <div>
            <p>Add new artworks in bulk</p>
            <div class="p-6 bg-white border-2 border-grey-500 space-y-8">

              <div>
                <label class="text-gray-700" for="source">
                  Sources
                  {sources.map(({ id, name }, index) => {
                    return (
                      <div class="block">
                        <input
                          key={index}
                          type="checkbox"
                          id={`checkbox-${id}`}
                          name={id}
                          value={id}
                          checked={checkedState[index]}
                          onChange={() => handleCheckboxChange(index, name)}
                          class="mr-1"
                        />
                        <label htmlFor={`checkbox-${id}`}>{name}</label>
                      </div>
                    );
                  })}
                </label>
              </div>

              <div>
                <label class="text-gray-700" for="searchKeyword">
                  Search query
                  <input type="text" name="query" onInput={handleChange} class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" autoComplete="off" placeholder="Your search"/>
                </label>
              </div>

              <div>
                <label class="text-gray-700" for="limit">
                  Number of posts to add
                  <input type="number" name="count" onInput={handleChange} defaultValue={inputs.count} min="1" max="30" class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" autoComplete="off" />
                </label>
              </div>

              <div className={`${error.length === 0 ? 'hidden' : ''} w-full shadow-md bg-yellow-100 border-yellow-600 text-yellow-500 border-l-4 p-4 my-4`} role="alert" >
                <p className="font-bold">Error</p>
                <p>{error}</p>
              </div>

              <div>
                <button type="button" onClick={handleSubmitRequest} class="block mx-auto py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                  Search
                </button>
              </div>

            </div>
          </div>
        </div>

        <div class="container w-2/3 bg-white border-2 border-grey-500">
          {
            suggestedPosts.length > 0 && (
              <div class="flex flex-wrap justify-around content-start items-center h-full overflow-x-auto">

                {
                  suggestedPosts.map(post => (
                    <FetchedPost postData={post} postIsSelected={post.resource_id === selectedPost.resource_id} setSelectedPost={setSelectedPost} />
                  ))
                }

              </div>
            )
          }
        </div>

      </div>

    </div>
  )
}

export default AdminBulkPage;
