import { h } from 'preact';
import { useContext } from 'preact/hooks';

import AuthContext from '../context/AuthContext';
import Redirect from './Redirect';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PrivateRoute = ({ component: Component, ...rest }) => {

  const { state } = useContext(AuthContext);

  if (state.isLoggedIn) {
    return (
      <div className="min-h-full flex flex-col justify-between">
        <Header />
        <div className="container mx-auto p-4">
          <Component {...rest}/>
        </div>
        <Footer />
      </div>
    )
  } else {
    return (
      <Redirect to="/auth" />
    )
  }
};

export default PrivateRoute;
