import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';

import AuthContext from '../context/AuthContext';
import { getLocalStorage } from '../utils/localStorage';

import { startLogout } from '../actions/authentication';
import { getCurrentUser, updateCurrentUser, updatePasswordCurrentUser } from '../actions/users';
import { createPortalSession } from '../actions/payments';

const AccountPage = () => {

  const { state, dispatch } = useContext(AuthContext);
  const [ userInfo, setUserInfo ] = useState({ ...state });
  const [ error, setError ] = useState('');
  const [ feedback, setFeedback ] = useState('');
  const localToken = getLocalStorage('token');

  useEffect(() => {
    if (state.isLoggedIn) {
      setUserInfo(state);
    } else {
      logout();
    }
  }, []);

  // Logout and clear session
  const logout = async () => {
    const result = await startLogout();
    if (result.type === 'LOGOUT') {
      dispatch(result);
      route('/auth', true);
    }
  }

  // Handle input changes
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setUserInfo(userInfo => ({
      ...userInfo,
      [name]: value
    }))
  }

  // Update user information
  const handleUpdateInfo = async (event) => {

    event.preventDefault();

    try {

      const result = await updateCurrentUser(userInfo.email, userInfo.username, state.token);

      if (result.type === 'UPDATE_PROFILE') {
        dispatch(result);
        setUserInfo(result);
        displayFeedback('success', 'Profile updated');
      } else {
        displayFeedback('error', result.detail);
        if (result.detail === 'Could not validate credentials') {
          logout();
        }
      }

    } catch (err) {
      displayFeedback('error', err.message);
    }
  };

  // Update user password
  const handleUpdatePassword = async (event) => {

    event.preventDefault();

    try {
      const oldPassword = event.target.elements.oldPassword.value.trim();
      const newPassword = event.target.elements.newPassword.value.trim();
      const result = await updatePasswordCurrentUser(oldPassword, newPassword, state.token);

      if (result.status === 'success') {
        displayFeedback('success', 'Password updated');
        event.target.reset();
      } else {
        displayFeedback('error', result.detail);
      }
    } catch (err) {
      displayFeedback('error', err.message);
    }
  }

  const handleCreatePortalSession = async (event) => {

    event.preventDefault();
    
    try {
      
      const result = await createPortalSession( state.token );

      if (result.status === 'success') {
        window.open(result.checkout_session_url, '_blank');
  
      } else {
        displayFeedback('error', result.message);
      }
    } catch (err) {
      displayFeedback('error', err.message);
    }
  };

  // Display and hide success or error message
  const displayFeedback = (type, message) => {

    if (type === 'success') {
      setFeedback(message);
    } else {
      setError(message);
    }

     setTimeout(() => {
       setFeedback('');
       setError('');
     }, 4000);
  }

  return (
    <section class="h-full">

      {/* Diplay feedback */}
      <div className={`${feedback.length === 0 ? 'hidden' : ''} max-w-2xl mx-auto shadow-md md:w-3/4 bg-blue-100 border-blue-600 text-blue-500 border-l-4 p-4 my-4`} role="alert" >
        <p className="font-bold">Info</p>
        <p>{feedback}</p>
      </div>

      {/* Diplay error */}
      <div className={`${error.length === 0 ? 'hidden' : ''} max-w-2xl mx-auto shadow-md md:w-3/4 bg-yellow-100 border-yellow-600 text-yellow-500 border-l-4 p-4 my-4`} role="alert" >
        <p className="font-bold">Error</p>
        <p>{error}</p>
      </div>

      <div class="container max-w-2xl mx-auto shadow-md md:w-3/4">
        <div class="p-4 rounded-lg bg-white">

          <form onSubmit={handleUpdateInfo} class="my-2">
            {/* Email */}
            <div class="items-center w-full p-4 text-gray-500 md:inline-flex">
              <h2 class="max-w-sm mx-auto md:w-1/3 mb-2 md:mb-0">
                Email
              </h2>
              <div class="max-w-sm mx-auto md:w-2/3">
                <input type="text" name="email" onInput={handleChange} defaultValue={state.email} class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" autoComplete="off" />
              </div>
            </div>

            {/* Username */}
            <div class="items-center w-full p-4 text-gray-500 md:inline-flex">
              <h2 class="max-w-sm mx-auto md:w-1/3 mb-2 md:mb-0">
                Username
              </h2>
              <div class="max-w-sm mx-auto md:w-2/3">
                <input type="text" name="username" onInput={handleChange} defaultValue={state.username} class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" autoComplete="off" />
              </div>
            </div>

            <div class="w-full p-4">
              <div class="max-w flex justify-center md:justify-end">
                <button type="submit" class="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                  Update
                </button>
              </div>
            </div>

          </form>

          <hr/>

          <form onSubmit={handleUpdatePassword} class="my-2">
            {/* Change password */}
            <div class="items-center w-full p-4 text-gray-500 md:inline-flex">
              <h2 class="max-w-sm mx-auto md:w-1/3 mb-2 md:mb-0">
                Change password
              </h2>
              <div class="max-w-sm mx-auto md:w-2/3 space-y-4">
                <div>
                  <input type="password" name="oldPassword" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" placeholder="Your current password" />
                </div>
                <div>
                  <input type="password" name="newPassword" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" placeholder="Your new password" />
                </div>
              </div>
            </div>

            <div class="w-full p-4">
              <div class="max-w flex justify-center md:justify-end">
                <button type="submit" class="py-2 px-4 bg-pink-600 hover:bg-pink-700 focus:ring-pink-500 focus:ring-offset-pink-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                  Change
                </button>
              </div>
            </div>

          </form>

          {state.isSubscribed && (
            <>

              <hr />

              <form onSubmit={handleCreatePortalSession} class="my-2">
                {/* Manage subscriptions */}
                <div class="items-center w-full p-4 text-gray-500 md:inline-flex">
                  <h2 class="max-w-sm mx-auto md:w-1/3 mb-2 md:mb-0">
                    Subscriptions
                  </h2>
                  <div class="max-w-sm mx-auto md:w-2/3 space-y-4">
                    <p>You are currently subscribed to Peerdiem on our { state.subscriptionType === 1 ? 'monthly' : 'yearly' } plan and as such you receive our daily newsletters.</p>
                    <p>You can manage your subscription by clicking on the button below.</p>
                  </div>
                </div>
                <div class="w-full px-4">
                  <div class="max-w flex justify-center md:justify-end">
                    <button type="submit" class="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                      Manage
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}

        </div>
      </div>
    </section>
  )
}

export default AccountPage;
