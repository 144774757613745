import { h } from 'preact';

const Comment = ({ comment, handleSetNewCommentInfo, handleEditComment, loggedInUser, isArchived }) => {

  const localTime = new Date(comment.submission_time.replace(' ', 'T') + "Z");
  const interval = Date.now() - new Date(localTime).getTime();
  const minutes = `${interval / 60000}`;

  let commentTime;
  if (minutes >= 1440) {
    commentTime = minutes / 1440 === 1 ? `1 day ago` : `${Math.floor(minutes / 1440)} days ago`;
  } else if (minutes >= 60) {
    commentTime = minutes / 60 < 2 ? `1 hour ago` : `${Math.floor(minutes / 60)} hours ago`;
  } else {
    commentTime = minutes < 2 ? `Just now` : `${Math.floor(minutes)} minutes ago`;
  }

  return (
    <div class={`mb-2 text-sm pl-${Number(comment.identifier) * 6}`}>
      <div>
        <span class="text-gray-600 font-bold">
          {comment.author.username}
        </span>
        <span class="text-gray-500 ml-2">
          {commentTime}
        </span>
      </div>
      <div>
        <p>{comment.content}</p>
        {
          !isArchived && <span class="text-xs text-gray-500 cursor-pointer" onClick={() => handleSetNewCommentInfo(comment.id, comment.identifier, comment.author.username)}>reply</span>
        }
        {/**
          comment.author.id === loggedInUser && <span class="text-xs text-gray-500 cursor-pointer" onClick={() => handleEditComment(comment.id)}>&nbsp;edit</span>
        **/}
      </div>
    </div>
  )
}

export default Comment;
