import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { getPosts } from '../actions/posts';

  // Format date to Tue May 12 2020
const FormattedDate = ({ date }) => {
  const formattedDate = new Date(date).toDateString();
  return <>{formattedDate}</>
}

const AllPostsPage = () => {

  const [allPosts, setAllPosts] = useState([]);
  const [feedbackText, setFeedbackText] = useState("Loading...");

  const date = new Date();
  const dDate = [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join('-');

  useEffect(() => {

    const fetchAllPosts = async () => {

      try {

        const result = await getPosts();

        if (result.status === 'success') {
          let archived_posts = result.posts.filter(post => {
            return post.media_name.split('.')[0] <= dDate;
          });
          setAllPosts(archived_posts);
        } else {
          setFeedbackText(result.detail || 'No post found.');
        }
      } catch (err) {
        setFeedbackText(err.messsage);
      }

    }

    fetchAllPosts()

  }, [])

  return (
    <div className="h-full w-screen mx-auto p-4">
      {
        Object.keys(allPosts).length > 0 ? (

          <div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 items-center">
            {
              allPosts.map((post, index) => (
                <>
                  <div class="group relative">
                    <div class="overflow-hidden rounded-md group-hover:opacity-75">
                      <img
                        src={`${process.env.BACKEND_BASE_URL}/static/${post.media_name}`}
                        alt={post.title}
                        loading="lazy"
                        class="h-full w-full object-cover object-center lg:h-full lg:w-full drop-shadow-2xl" 
                      />
                    </div>
                    <div class="mt-4 flex justify-center">
                      <div>
                        <h3 class="text-sm text-gray-700">
                          <a href={`posts/${post.media_name.split('.')[0] === dDate ? 'today' : post.id}`}>
                            <span aria-hidden="true" class="absolute inset-0"></span>
                            <FormattedDate date={post.media_name.split('.')[0]} />
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                </>
              ))
            }
          </div>
        ) : (
          <p class="text-center">{feedbackText}</p>
        )
      }
    </div>
  )
}

export default AllPostsPage;
