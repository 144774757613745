import validateForm from '../utils/validateForm';
import authHeaders from '../utils/authHeaders';

// Get information about current user
export const getCurrentUser = async (token) => {

  try {

    const url = `${process.env.API_URL}/users/me`;

    const response = await fetch(url, authHeaders(token));
    const json = await response.json();

    if (json.email) {
      return { ...current(json), token };
    } else {
      return "No active session. Please log in."
    }

  } catch (err) {
    return err.message;
  }

}

// Update information of current user
export const updateCurrentUser = async (email, username, token) => {

  try {

    const [formIsValid, message] = validateForm({ email, username });

    if (formIsValid) {

      const url = `${process.env.API_URL}/users/me`;
      const headers = authHeaders(token);

      const response = await fetch(url, {
        method: "put",
        ...headers,
        body: JSON.stringify({email, username}),
      });

      const json = await response.json();

      if (json.email) {
        return { ...update(json), token };
      } else {
        return json;
      }

    } else {
      return message;
    }
  } catch (err) {
    return err.message;
  }
};


// Update password of current user
export const updatePasswordCurrentUser = async (oldPassword, newPassword, token) => {

  try {

    const [formIsValid, message] = validateForm({ oldPassword, newPassword });

    if (formIsValid) {

      const url = `${process.env.API_URL}/users/me`;
      const headers = authHeaders(token);

      const response = await fetch(url, {
        method: "put",
        ...headers,
        body: JSON.stringify({old_password: oldPassword, new_password: newPassword}),
      });

      const json = await response.json();

      if (json.email) {
        return { status: 'success' };
      } else {
        return json;
      }

    } else {
      return message;
    }
  } catch (err) {
    return err.message;
  }
};


// Actions to change state
export const current = (user) => ({
  type: 'ACTIVE',
  uid: user.id,
  email: user.email,
  username: user.username,
  isLoggedIn: true,
  isActive: user.is_active,
  isSuperuser: user.is_superuser,
  isSubscribed: user.is_subscribed,
  subscriptionType: user.subscription_type,
  isOptedIn: user.is_opted_in
});

export const update = (user) => ({
  type: 'UPDATE_PROFILE',
  uid: user.id,
  email: user.email,
  username: user.username,
  isLoggedIn: true,
  isActive: user.is_active,
  isSuperuser: user.is_superuser,
  isSubscribed: user.is_subscribed,
  subscriptionType: user.subscription_type,
  isOptedIn: user.is_opted_in
});
