import authHeaders from '../utils/authHeaders';

// Create a new comment
export const createNewComment = async (postId, parentId, identifier, comment, token) => {

  try {

    const newComment = {
      post_id: postId,
      parent_id: parentId,
      identifier,
      content: comment
    }

    const url = `${process.env.API_URL}/comments`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(newComment)
    })

    const json = await response.json();

    if (json.id) {
      return { status: 'success', message: 'Comment was successfully added', comment: json };
    } else {
      return json;
    }
  } catch (err) {
    return err.message;
  }

}

// Get comments for today post
export const getTodayComments = async (postId) => {

  try {

    const url = `${process.env.API_URL}/posts/${postId}/comments`;
    const response = await fetch(url)
    const json = await response.json();

    if (json.length > 0) {
      return { status: 'success', message: 'Comments on today post were successfully retrieved', comments: json };
    } else {
      return { status: 'fail', message: 'No comments found' };
    }
  } catch (err) {
    return err.message;
  }

}


