export const setLocalStorage = (key, value) => {
  if (localStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export const getLocalStorage = (key) => {
  if (localStorage && localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
  return null;
}

export const checkValidSession = (session, sessionExpiryDate) => {
  if (localStorage && localStorage.getItem(sessionExpiryDate)) {
    let now = Date.now();
    let expires = JSON.parse(localStorage.getItem(sessionExpiryDate));
    if (now > expires) {
      clearLocalStorage(session);
      clearLocalStorage(sessionExpiryDate);
      return false;
    }
    return true;
  }
  return false;
}

export const clearLocalStorage = (key) => {
  if (localStorage) {
    localStorage.removeItem(key);
  }
}
