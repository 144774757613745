import { h, Component } from 'preact';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <div className="min-h-full flex flex-col justify-between">
      <Header path={rest.path} />
      <Component {...rest}/>
      <Footer />
    </div>
  )
};

export default PublicRoute;
