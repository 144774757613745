import { h } from 'preact';

const FetchedPost = ({ postData, postIsSelected, setSelectedPost }) => {

  const handleSelectPost = () => {
    setSelectedPost(postData);
  }

  // Correct source of images if resource can't be loaded
  // Currently only used for WikiArt fetches
  const addDefaultSrc = (event) => {
    // 
    let currentSrc = event.target.src;
    const regex = /!.*$/;
    let newSrc = currentSrc.replace(regex, "");
    event.target.src = newSrc;

    // Update selected post state
    postData.image_url = newSrc;
  }

  return (
    <img onError={addDefaultSrc} src={postData.low_res_image_url ? postData.low_res_image_url : postData.image_url} onClick={handleSelectPost} class={`w-1/4 m-2 cursor-pointer ${postIsSelected ? 'border-4 border-blue-400' : 'border-4 border-transparent'}`}></img>
  )
}

export default FetchedPost;
