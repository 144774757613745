import { h } from 'preact';
import { Router, Navigate } from 'preact-router';
import { createBrowserHistory } from 'history';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// import MainPage from '../components/MainPage_old';
import Redirect from '../components/Redirect';
import AuthPage from '../components/AuthPage';
import ResetPasswordPage from '../components/ResetPasswordPage';
import PremiumPage from '../components/PremiumPage';
import AllPostsPage from '../components/AllPostsPage';
import PostPage from '../components/PostPage';
import PostTomorrowPage from '../components/PostTomorrowPage';
import AdminPage from '../components/AdminPage';
import AdminBulkPage from '../components/AdminBulkPage';
import AccountPage from '../components/AccountPage';
import NotFoundPage from '../components/NotFoundPage';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    {/* <PublicRoute path="/" component={MainPage} exact={true} /> */}
    <Redirect path="/" to="/posts/today" />
    <PublicRoute path="/auth" component={AuthPage} />
    <PublicRoute path="/reset-password" component={ResetPasswordPage} />
    <PublicRoute path="/posts" component={AllPostsPage} />
    <PublicRoute path="/posts/tomorrow" component={PostTomorrowPage} />
    <PublicRoute path="/posts/:id" component={PostPage} />
    <PublicRoute path="/reset-password" component={ResetPasswordPage} />
    <PublicRoute path="/subscriptions" component={PremiumPage} />
    <PrivateRoute path="/admin" component={AdminPage} />
    <PrivateRoute path="/admin-bulk" component={AdminBulkPage} />
    <PrivateRoute path="/me" component={AccountPage} />
    <PublicRoute path="/:remaining_path*" component={NotFoundPage}  />
  </Router>
);

export default AppRouter;
