const validateForm = ({ username, email, password, passwordConfirm, oldPassword, newPassword }, origin) => {

  let inputs = {
    username,
    email,
    password,
    oldPassword,
    newPassword
  }

  const [isEmpty, input] = inputIsEmpty(inputs);
  const isEmail = inputIsEmail(email);

  if (email && email.length > 0 && !isEmail) {
    return [false, `Email format is not valid`];
  }

  // if (username && username.length > 16) {
  //   return [false, `Username must be 16 characters maximum`];
  // }

  if (isEmpty) {
    return [false, `${input.charAt(0).toUpperCase() + input.slice(1)} is required`];
  }

  // Test on passwords for registration or reset
  if (password !== undefined && passwordConfirm !== undefined && password !== passwordConfirm) {
    return [false, 'Passwords do not match'];
  }

  if (password !== undefined && passwordConfirm !== undefined && password.toLowerCase().includes('password')) {
    return [false, 'Password cannot contain the word "password"'];
  }

  if (password !== undefined && passwordConfirm !== undefined && password.length < 8) {
    return [false, 'Password must be at least 8 characters long'];
  }

  // Test on passwords for password update
  if (oldPassword !== undefined && newPassword !== undefined && oldPassword === newPassword) {
    return [false, 'New password is the same as current'];
  }

  if (oldPassword !== undefined && newPassword !== undefined && newPassword.toLowerCase().includes('password')) {
    return [false, 'Password cannot contain the word "password"'];
  }

  if (oldPassword !== undefined && newPassword !== undefined && newPassword.length < 8) {
    return [false, 'Password must be at least 8 characters long'];
  }

  return [true, 'Form is valid'];

}

const inputIsEmpty = (inputs) => {
  for (let input in inputs){
    if (inputs[input] !== undefined && !inputs[input].length > 0) {
      return [true, input];
    }
  }
  return [false, undefined];
}

const inputIsEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export { validateForm as default };
