import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import LoginPage from '../components/LoginPage';
import SignupPage from '../components/SignupPage';

const AuthPage = (props) => {

  const [toggleVariable, setToggleVariable] = useState(true);

  useEffect(() => {
    // Check to see if this is a new sign up
    const query = new URLSearchParams(window.location.search);
    if (query.get('signup')) {
      setToggleVariable(false);
    }
  }, []);

  const updateToggleVariable = () => {
    setToggleVariable(!toggleVariable);
  }

  return (
    <div class="my-4">
      {
        toggleVariable ? <LoginPage updateToggleVariable={updateToggleVariable} />
        : <SignupPage updateToggleVariable={updateToggleVariable} />
      }
    </div>
  );
}

export default AuthPage;
