import authHeaders from '../utils/authHeaders';

// Get all posts
export const getPosts = async () => {

  try {
    const url = `${process.env.API_URL}/posts`;
    const response = await fetch(url)
    const json = await response.json();

    if (response.status === 200) {
      return { status: 'success', message: 'Posts were successfully retrieved', posts: json };
    } else {
      return { status: 'failure', message: 'Posts could not be retrieved' };
    }
  } catch (err) {
    return err.message;
  }
}

// Get a post by id
export const getPost = async (id) => {

  try {
    const url = `${process.env.API_URL}/posts/${id}`;
    const response = await fetch(url)
    const json = await response.json();

    if (response.status === 200) {
      return { status: 'success', message: 'Post was successfully retrieved', post: json };
    } else {
      return { status: 'failure', message: 'Post could not be retrieved' };
    }
  } catch (err) {
    return err.message;
  }

}


// Get post for current date - With server date
export const getTodayPost = async () => {

  try {

    const url = `${process.env.API_URL}/posts/today`;
    const response = await fetch(url)
    const json = await response.json();

    if (json.id) {
      return { status: 'success', message: 'Today post was successfully retrieved', post: json };
    } else {
      return json;
    }
  } catch (err) {
    return err.message;
  }

}

// Get today post - With client based date
export const getTodayPostWithTimezones = async () => {

  try {

    const date = new Date();
    const dDate = [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join('-');

    const url = `${process.env.API_URL}/posts/today`;

    const response = await fetch(url, {
      method: "post",
      body: JSON.stringify(dDate)
    })

    const json = await response.json();

    if (json.id) {
      return { status: 'success', message: 'Today post was successfully retrieved', post: json };
    } else {
      return json;
    }
  } catch (err) {
    return err.message;
  }

}


// Get tomorrow post - With client based date
export const getTomorrowPostWithTimezones = async () => {

  try {

    const date = new Date();
    const dDate = [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join('-');

    const url = `${process.env.API_URL}/posts/tomorrow`;

    const response = await fetch(url, {
      method: "post",
      body: JSON.stringify(dDate)
    })

    const json = await response.json();

    if (json.id) {
      return { status: 'success', message: 'Today post was successfully retrieved', post: json };
    } else {
      return json;
    }
  } catch (err) {
    return err.message;
  }

}


// Create a new post
export const createNewPost = async (post, submissionDate, token) => {

  try {

    const newPost = {
      ...post,
      submission_time: submissionDate,
    }

    const url = `${process.env.API_URL}/posts`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(newPost)
    })

    const json = await response.json();

    if (json.id) {
      return { status: 'success', message: 'Post was successfully added' };
    } else {
      return json;
    }
  } catch (err) {
    return err.message;
  }

}

// Create multiple posts
export const createNewPosts = async (posts, token) => {

  try {

    const url = `${process.env.API_URL}/posts`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(posts)
    })

    const json = await response.json();

    if (json.id) {
      return { status: 'success', message: 'All posts were successfully added' };
    } else {
      return json;
    }
  } catch (err) {
    return err.message;
  }

}


