import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { getTomorrowPostWithTimezones } from '../actions/posts';

import { Pixelify } from "react-pixelify";

const PostTomorrowPage = () => {

  const [post, setPost ] = useState({});
  const [imgUrl, setImgUrl ] = useState('');
  const [feedbackText, setFeedbackText ] = useState("Loading...");
  
  useEffect(() => {

    const fetchPost = async () => {

      try {

        let result = await getTomorrowPostWithTimezones();
  
        if (result.status === 'success') {
          setPost(result.post);
          const url = `${process.env.BACKEND_BASE_URL}/static/${result.post.media_name}`;
          setImgUrl(url);
        } else {
          setFeedbackText(result.detail || 'No painting found.');
        }

      } catch (err) {
        setFeedbackText(err.messsage);
      }
  
    }
    
    fetchPost();

  }, [])

  return (
    <div className="h-full p-4 container mx-auto">
      
      {/* TODAY */}
      <h3 class="text-center py-4">Guess the painting</h3>

      {
        Object.keys(post).length > 0 ? (
          <div class="flex flex-col xl:flex-row justify-center content-center">
            <div>
              <Pixelify
                style="max-height: 75vh"
                src={imgUrl}
                pixelSize={4}
              />
              <img className="mx-auto" src={imgUrl} style="max-height: 75vh"></img>          
            </div>
        </div>
        ) : (
          <p class="text-center">{feedbackText}</p>
        )
      }
    </div>
  )
}

export default PostTomorrowPage;
