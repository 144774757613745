import { h } from 'preact';
import { useState } from 'preact/hooks';

const sources = {
  artic: 'Art Institute of Chicago',
  cma: 'Cleveland Museum of Art',
  met: 'Metropolitan Museum of Art',
  rijks: 'Rijksmuseum'
}

const PostInfo = ({ post, isAdminView }) => {

  return (
    <div class={`mx-auto w-full md:w-1/2 ${isAdminView ? 'md:w-full' : 'mb-6'}`}>
      <div class="w-full flex flex-col justify-between pt-6 space-y-4">

        <div class="flex flex-wrap">

          {/* Title + Dates */}
          <h1 class="flex-auto text-xl font-semibold">
            {post.title} {(post.date_start || post.date_end) && <>{post.date_start === post.date_end ? `(${post.date_end})` : `(${post.date_start}-${post.date_end})`}</>}
          </h1>

          {/* Artist name */}
          <div class="text-xl font-semibold text-gray-500">
            {post.artist_title}
          </div>

          {/* Dimensions
          Fine art is listed as Height x Width x Depth in inches or centimeters.
          Paintings are measured by height first, followed by width.
          Sculptures and three-dimensional installations are measured by height, width, and depth.
          */}
          {((post.width && post.height) || isAdminView) && 
            <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
              <span class="font-extrabold">Dimensions:</span> H {post.height} x W {post.width} cm
            </div>
          }
          {/* Style */}
          {(post.style || isAdminView) && 
            <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
              <span class="font-extrabold">Style:</span> {post.style}
            </div>
          }

          {/* Genre */}
          {(post.genre || isAdminView) && 
            <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
              <span class="font-extrabold">Genre:</span> {post.genre}
            </div>
          }

          {/* Department */}
          {(post.department_title || isAdminView) && 
            <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
              <span class="font-extrabold">Department:</span> {post.department_title}
            </div>
          }

          {/* Medium */}
          {(post.medium_display || isAdminView) && 
            <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
              <span class="font-extrabold">Medium:</span> {post.medium_display}
            </div>
          }

          {/* Collection */}
          {((post.collection || sources[post.source]) || isAdminView) && 
            <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
              <span class="font-extrabold">Collection:</span> {post.collection ? post.collection : sources[post.source]}
            </div>
          }

          {/* Copyright */}
          {(post.copyright_notice || isAdminView) && 
            <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
              <span class="font-extrabold">Copyright:</span> {post.copyright_notice}
            </div>
          }

          {/* Additional post information for admin only */}
          {isAdminView &&
            <div>
              {/* Copyright */}
              {post.image_url &&
                <div class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
                  <span class="font-extrabold">Image URL:</span> {post.image_url}
                </div>
              }
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export default PostInfo;
