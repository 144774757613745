import { h } from 'preact';
import AuthProvider from '../components/AuthProvider';
import AppRouter from '../routers/AppRouter';

const App = () => {

  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  )
}

export default App;
