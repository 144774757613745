// Return Authorization Token in Headers
const authHeaders = (token) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  };
}

export default authHeaders;
