import { setLocalStorage, clearLocalStorage } from '../utils/localStorage';

const auth = (state, action) => {

  let newState;
  let expires;

  switch (action.type) {
    case 'ACTIVE':
      newState = {
        uid: action.uid,
        email: action.email,
        username: action.username,
        isLoggedIn: action.isLoggedIn,
        isActive: action.isActive,
        isSuperuser: action.isSuperuser,
        isSubscribed: action.isSubscribed,
        subscriptionType: action.subscriptionType,
        isOptedIn: action.isOptedIn,
        token: action.token
      }
      return newState;

    case 'UPDATE_PROFILE':
      newState = {
        ...state,
        email: action.email,
        username: action.username,
        isActive: action.isActive,
        isSubscribed: action.isSubscribed,
        subscriptionType: action.subscriptionType,
        isOptedIn: action.isOptedIn
      }
      // setLocalStorage('session', newState);
      return newState;

    case 'SIGNUP':
      newState = {
        uid: action.uid,
        email: action.email,
        username: action.username,
        isLoggedIn: action.isLoggedIn,
        isActive: action.isActive,
        isSuperuser: action.isSuperuser,
        isSubscribed: action.isSubscribed,
        subscriptionType: action.subscriptionType,
        isOptedIn: action.isOptedIn
      }
      // expires = Date.now() + (60*24*8*60*1000);
      // setLocalStorage('expires', expires);
      // setLocalStorage('session', newState);
      return newState;

    case 'LOGIN':
      newState = {
        uid: action.uid,
        email: action.email,
        username: action.username,
        isLoggedIn: action.isLoggedIn,
        isActive: action.isActive,
        isSuperuser: action.isSuperuser,
        isSubscribed: action.isSubscribed,
        subscriptionType: action.subscriptionType,
        isOptedIn: action.isOptedIn,
        token: action.token
      }
      // expires = Date.now() + (60*24*8*60*1000);
      // setLocalStorage('expires', expires);
      setLocalStorage('token', action.token);
      return newState;

    case 'LOGOUT':
      clearLocalStorage('token');
      // clearLocalStorage('expires');
      return {}

    default:
      return state;
  }
}

export default auth;
