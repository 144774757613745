// Prompt to encourage discussions 
export const prompts = [
    "What's going on in this artwork?",
    "What was your first reaction to this artwork? Why do you think you had this reaction? ",
    "Does your opinion about this artwork change the longer you look at it? Why?",
    "Describe the lines in this artwork",
    "Describe the colors in this artwork",
    "Which area of this artwork is emphasized by the artist? Why?",
    "Which area of this artwork is most important? Why?",
    "How did the artist use space?",
    "How did the artist use balance?",
    "How did the artist use proportion?",
    "How does your eye move through this artwork?",
    "Close your eyes and describe this artwork from memory. Why did you remember what you remembered? Why did you forget what you forgot?",
    "How would you describe this artwork to someone who has never seen it?",
    "What elements of this artwork seem real?",
    "What adjectives would you use to describe this artwork?",
    "What verbs would you use to describe this artwork?",
    "What elements seem dreamlike or imaginary?",
    "What is strange or mysterious about this artwork?",
    "What is boring about this artwork?",
    "What is exciting about this artwork?",
    "If this artwork had sound effects, what would they sound like?",
    "If this artwork was a brand, what would be its slogan?",
    "What is missing from this artwork?",
    "If this artwork were music, what would it sound like?",
    "What do we know about the artist after viewing this artwork?",
    "If you could ask the artist a question, what would you ask them?",
    "Who do you think was this artwork created for? Why do you say that?",
    "How do you think the artist feels or felt about the final product?",
    "How do you think this artwork was made?",
    "Why do you think this artist created this work?",
    "What do you think this artist is trying to say in this artwork? What is the meaning or message?",
    "What's the story being told, if any?",
    "What do you think happened before this scene? What do you think happened next?",
    "What emotions do you notice in the artwork?",
    "What emotions do you feel when looking at this?",
    "How do you think the artist was feeling when he created this artwork?",
    "How did the artist use line, shape, and color to contribute to the mood or meaning?",
    "What is the title? How does the title contribute to your understanding of the meaning?",
    "What title would you give this artwork?",
    "What symbols do you notice in the artwork?",
    "What juxtapositions do you notice?",
    "Put your body into the pose of some element of this artwork. How does it feel to be in that position?",
    "What would it feel like to be in this artwork?",
    "What does this artwork remind you of? Why?",
    "How do you personally relate to/connect with this picture?",
    "How can you connect this artwork to your own life?",
    "How might you feel differently about the world after looking at this artwork?",
    "Why do you think you should be learning about/looking at this artwork?",
    "Do you want to see this artwork again? Why?",
    "What do you want to remember about this artwork?",
    "What do you want to forget about this artwork?",
    "Who do you know that would really like this artwork? Why would they like it?",
    "Who do you know that would really hate this artwork? Why would they hate it?",
    "What do you like about this artwork?",
    "What do you dislike about this artwork?",
    "What is beautiful about this artwork?",
    "Why would someone want to steal this artwork?",
    "If you could change this artwork, how would you change it? Why?",
    "What does this artwork say about the culture in which it was produced?",
    "How do you think this artwork was used by the people who made it? What was its function?",
    "Was this intended to be a work of art or not? Why do you think that? How does that impact your understanding of the artwork?",
    "What does this painting say about the world in which we live?",
    "What does this artwork teach us about the past?",
    "What does this artwork teach us about the future?",
    "What was happening in history when this artwork was made? How does that change your understanding of the artwork?",
    "What are the values and beliefs of the culture in which this artwork was made?",
    "How might your interpretation of this artwork be different from someone in another culture?"
]

export const promptsColours = ["#FCE1E4","#FCF4DD","#DDEDEA"];