// 
export const getRandomInt = (quantity, max) => {
    const set = new Set();
    while (set.size < quantity) {
        set.add(Math.floor(Math.random() * max) + 1);
    }
    return set;
}

//
export const getRandomArrayElt = (quantity, max, array) => {
    const set = new Set();
    while (set.size < quantity) {
        set.add(array[Math.floor(Math.random() * max)]);
    }
    return set;
}