import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { Link, route } from 'preact-router';
import { activeSession, startLogout } from '../actions/authentication';
import AuthContext from '../context/AuthContext';

const Header = (props) => {

  const { state, dispatch } = useContext(AuthContext);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);

  const toggleNavOpen = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleAdminMenu = () => {
    setIsAdminMenuOpen(!isAdminMenuOpen);
  };

  const logout = async () => {

    setIsNavOpen(false);

    const result = await startLogout();

    if (result.type === 'LOGOUT') {
      dispatch(result);
      route('/auth', true);
    }
  }

  return (
    <div>
      <nav className="bg-white dark:bg-gray-800 shadow">
        <div className="px-2 sm:px-8">
          <div className="flex items-center justify-between h-16">

            {/* Left Menu */}
            <div className="flex items-center">
              <Link className="flex-shrink-0" href="/">
                <img className="h-6 hidden sm:block" src="../assets/peerdiem_logo.png" alt="logo" />
                <img className="h-12 sm:hidden" src="../assets/peerdiem_mobile_logo.png" alt="logo" />
              </Link>
            </div>

            {/* Right Menu */}
            <div className="flex items-center">
              {
                (props.path !== '/subscriptions' && !state.isSubscribed) && (
                  <Link href="/subscriptions" class="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                    Daily Newsletter 📥
                  </Link>
                )
              }

              {/* Desktop Menu */}
              <div className="hidden md:block">
                {
                  state.isSuperuser && (
                    <>
                      <Link className="relative text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium cursor-pointer" onClick={toggleAdminMenu}>
                        Admin
                      </Link>
                      <Link href="/posts" className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                        Archives
                      </Link>
                      {
                        isAdminMenuOpen && (
                          <div class="absolute mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                              <Link href="/admin" class="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <span class="flex flex-col">
                                  Manual Selection
                                </span>
                              </Link>
                              <Link href="/admin-bulk" class="block block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                <span class="flex flex-col">
                                  Bulk Selection
                                </span>
                              </Link>
                            </div>
                          </div>
                        )
                      }
                    </>
                  )
                }
                {
                  !state.isLoggedIn ? (
                    <Link href="/auth" className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                      Login
                    </Link>
                  ) : (
                    <>
                      <Link href="/me" className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                        Account
                      </Link>
                      <button onClick={logout} className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                        Logout
                      </button>
                    </>
                  )
                }
              </div>

              {/* Mobile hamburger menu */}
              <button onClick={toggleNavOpen} data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </button>

              {isNavOpen && (
                <div className="md:hidden">
                  <div className="absolute top-16 right-0 w-full sm:w-1/2 z-10 shadow-lg bg-white ring-1 ring-black ring-opacity-5 flex flex-col divide-y divide-gray-200">
                    {
                      state.isSuperuser && (
                        <Link onClick={toggleNavOpen} className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium" href="/admin">
                          Admin
                        </Link>
                      )
                    }
                    <Link onClick={toggleNavOpen} href="/posts" className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                      Archives
                    </Link>
                    {
                      !state.isLoggedIn ? (
                        <Link onClick={toggleNavOpen} href="/auth" className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                          Login
                        </Link>
                      ) : (
                        <>
                          <Link onClick={toggleNavOpen} href="/me" className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                            Account
                          </Link>
                          <Link onClick={logout} href="/" className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md text-sm font-medium">
                            Logout
                          </Link>
                        </>
                      )
                    }
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </nav>
    </div>
  );

  return (
    <header>
      <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          {/* <a href="#" class="flex items-center">
            <img src="https://flowbite.com/docs/images/logo.svg" class="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
            <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
          </a> */}
          <Link className="flex-shrink-0" href="/">
            <img className="h-6" src="../assets/peerdiem_logo.png" alt="logo" />
          </Link>
          <div class="flex items-center lg:order-2">
            {
              (props.path !== '/subscriptions' && !state.isSubscribed) && (
                <Link href="/subscriptions" class="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                  Daily Newsletters 📥
                </Link>
              )
            }            
            <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
              <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
          </div>
          <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
            <div class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              {
                state.isSuperuser && (
                  <>
                    <Link href="/admin" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700">
                      Admin
                    </Link>
                  </>
                )
              }
              <Link href="/posts" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700">
                Archives
              </Link>
              {
                !state.isLoggedIn ? (
                  <Link href="/auth" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700">
                    Login
                  </Link>
                ) : (
                  <>
                    <Link href="/me" className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700">
                      Account
                    </Link>
                    <button onClick={logout} className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:bg-gray-700 lg:dark:hover:bg-transparent dark:border-gray-700">
                      Logout
                    </button>
                  </>
                )
              }
            </div>
          </div>
        </div>
      </nav>
    </header>
  )

}

export default Header;
