import { h } from 'preact';
import { Link } from 'preact-router';

const Footer = () => (
  <footer class="bg-white dark:bg-gray-800 w-full py-2 shadow-inner">
    <div class="max-w-screen-xl mx-auto px-4 text-sm flex justify-center">
      <span>Follow <a href="https://twitter.com/aco_le" className="font-bold">@aco_le</a> to get the latest updates</span>
    </div>
  </footer>
)

export default Footer;
