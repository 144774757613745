import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { createNewComment, getTodayComments } from '../actions/comments';
import AuthContext from '../context/AuthContext';
import { prompts as allPrompts, promptsColours } from '../data/constants';
import { getRandomArrayElt } from '../utils/mathHelpers';
import Comment from './Comment';


const CommentPage = ({ post, toggleComments, isArchived }) => {

  const { state } = useContext(AuthContext);

  const [newComment, setNewComment] = useState('');
  const [todayComments, setTodayComments] = useState([]);
  const [commentsReady, setCommentsReady] = useState(false);

  const [prompts, setPrompts] = useState([]);
  const [togglePrompts, setTogglePrompts] = useState(false);

  const [parentId, setParentId] = useState(0);
  const [identifier, setIdentifier] = useState(0);
  const [recipient, setRecipient] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {

    const fetchTodayPostComments = async () => {

      try {
        // TODO: Fetch comments once on home page
        const result = await getTodayComments(post.id);

        if (result.status === 'success') {
          orderComments(result.comments);
        }
        setCommentsReady(true);
      } catch (err) {
        setError(err.message);
        timeOutError();
      }
    }

    fetchTodayPostComments();

    // Get 3 random prompts to encourage discussion
    setPrompts(Array.from(getRandomArrayElt(3, allPrompts.length, allPrompts)));

  }, [])

  //
  const handleTogglePrompts = () => {
    setTogglePrompts(!togglePrompts);
  }

  // Order comments in a thread parent-child relationships
  const orderComments = (comments) => {

    let orderedComments = []

    const order = (i, parent) => {

      let children = comments.filter(comment => Number(comment.identifier) === i).filter(comment => Number(comment.parent_id) === parent);

      for (let index = 0; index < children.length; index++) {

        let child = children[index];
        let gchildren = comments.filter(comment => Number(comment.identifier) === i + 1).filter(comment => Number(comment.parent_id) === child.id)

        if (gchildren.length === 0) {
          orderedComments.push(child)
        } else {
          order(i + 1, child.id)
          orderedComments.push(child)
        }
      }
    }
    order(0, 0)
    setTodayComments(orderedComments.reverse());
  }

  // Insert new comment in the right index of the array
  const insertChildComment = (newComment) => {

    let newCommentArray = todayComments;
    let insertIndex = newCommentArray.findIndex(comment => comment.id === newComment.parent_id);

    // Only insert new comment if the parent was found
    if (insertIndex !== -1) {
      newCommentArray.splice(insertIndex + 1, 0, newComment);
      setTodayComments(newCommentArray);
    }
  }

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  }

  const handleSubmitComment = async () => {

    setError('');

    if (!state.isLoggedIn) {
      // Check if user is logged in
      setError('Log in to submit your comment.');
      timeOutError();
    } else if (newComment.length <= 30) {
      // Check min length of comment
      setError('Comments should be at least 30 characters.');
      timeOutError();
    } else {
      // Submit comment
      try {

        const result = await createNewComment(post.id, parentId, identifier, newComment, state.token);

        if (result.status === 'success') {
          if (result.comment.identifier === 0) {
            // Add the newest top-level comment at the top
            setTodayComments([result.comment, ...todayComments]);
          } else {
            // Insert the new child comment in the correct space
            insertChildComment(result.comment);
          }

          // Reset other states
          setNewComment('');
          setIdentifier(0);
          setParentId(0);
          setRecipient('');
        } else {
          setError(result.detail || 'There was an error. Try again later.');
          timeOutError();
        }

      } catch (err) {
        setError(err.message);
        timeOutError();
      }
    }

  }

  const handleSetNewCommentInfo = (parentId, identifier, username = "") => {

    let parent_id = Number(parentId);
    let id = parent_id === 0 ? Number(identifier) : Number(identifier) + 1;

    setParentId(parent_id);
    setIdentifier(id);
    setRecipient(username);
  }

  const handleEditComment = (commentId) => {
    console.log('Comment: ', commentId);
  }

  const timeOutError = () => {
    setTimeout(() => setError(''), 4000);
  }

  return (
    <div class={toggleComments && 'xl:w-3/5 px-6'}>

      <div className={`${error.length === 0 ? 'hidden' : ''} bg-blue-100 border-blue-600 text-blue-500 border-l-4 p-4 my-4`} role="alert">
        <p>{error}</p>
      </div>

      {
        !isArchived && (
          <>
            <div class="pl-2 pb-2">
              <div class="flex justify-between">
                {identifier !== 0 ? (
                  <div><span class="text-gray-600 font-bold">Reply to:</span> {recipient} <span class="cursor-pointer" onClick={() => handleSetNewCommentInfo(0, 0)}>&#10006;</span></div>
                ) : (
                  <span class="text-gray-600">What do you think of this artwork?</span>
                )}
                {
                  (!togglePrompts && todayComments.length !== 0) && (
                    <p class="text-blue-500 cursor-pointer" onClick={handleTogglePrompts}>
                      Don't know where to start?
                    </p>
                  )
                }
              </div>

              {(togglePrompts && todayComments.length !== 0) && (
                <>
                  {
                    prompts.length > 0 && (
                      <div class="flex flex-wrap">
                        {
                          prompts.map((prompt, index) => (
                            <span class="text-sm p-1 mr-1 mb-1" style={`background-color: ${promptsColours[index]}`}>{prompt}</span>
                          ))
                        }
                      </div>
                    )
                  }
                </>
              )}

            </div>

            <label class="text-gray-700" for="comment">
              <textarea value={newComment} onInput={handleCommentChange} class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 text-sm placeholder-gray-400 rounded-lg text-base focus:outline-none" id="comment" placeholder="Type your comment here.." name="comment" rows="2" required>
              </textarea>
            </label>

            <div class="mt-1 mb-4 mx-auto text-sm font-medium text-right">
              <button onClick={handleSubmitComment} class="bg-white hover:bg-gray-100 text-gray-800 font-semibold p-2 border border-gray-400 rounded shadow">
                Submit
              </button>
            </div>
          </>
        )
      }

      {
        todayComments.length > 0 ? (
          todayComments.map(comment => (
            <Comment key={comment.id} comment={comment} handleSetNewCommentInfo={handleSetNewCommentInfo} handleEditComment={handleEditComment} loggedInUser={state.uid} isArchived={isArchived} />
          ))
        ) : (
          <>
            {
              commentsReady && (
                <>
                  <p class="text-center">There are no comments yet.</p>
                  <br />
                  <p class="text-center font-semibold">What about you?</p>
                  {
                    prompts.length > 0 && (
                      <div class="flex flex-col justify-center content-center items-center">
                        {
                          prompts.map((prompt, index) => (
                            <span class="text-center p-1 mt-2" style={`background-color: ${promptsColours[index]}`}>{prompt}</span>
                          ))
                        }
                      </div>
                    )
                  }
                </>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default CommentPage;
