import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

const Modal = ({ selectedPost, setDisplayEditModal, setSelectedPost }) => {

  const [ updatedSelectedPost, setUpdatedSelectedPost] = useState(selectedPost);

  const handleChange = (event) => {

    let name = event.target.name;
    let value = event.target.value;

    setUpdatedSelectedPost(prevState => ({
      ...prevState,
      [name]: value
    }))

  }

  // Apply changes to selected post + close modal
  const handleUpdateSelectedPost = () => {
    setSelectedPost(updatedSelectedPost);
    setDisplayEditModal(0);
  }
  
  return (
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {/* 
      Background backdrop, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0" 
      */}
      
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

          {/*
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          */}

          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Edit selected post</h3>
                <div class="mt-2">
                  {
                    // 
                    Object.keys(updatedSelectedPost).map((val, key) => (
                      <div key={key} class="w-full flex-none text-sm font-medium text-gray-500 mt-2">
                        {/* <span class="font-extrabold">{val}:</span> {selectedPost[val]} */}
                        <label class="block text-gray-700 text-sm font-bold mb-2" for={val}>{val}</label>
                        <textarea name={val} value={updatedSelectedPost[val]} onInput={handleChange} class="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 text-sm placeholder-gray-400 rounded-lg text-base focus:outline-none" rows="2"></textarea>
                    </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button onClick={handleUpdateSelectedPost} type="button" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Save</button>
              <button onClick={()=>setDisplayEditModal(0)} type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;
