import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import PostInfo from './PostInfo';
import CommentPage from './CommentPage';
import { getPost, getTodayPostWithTimezones } from '../actions/posts';


const CommentsButton = ({ post, handleToggleComments, toggleText }) => (
  <div class='mx-auto mb-6 md:w-1/2 text-sm font-medium flex flex-col justify-between'>
    <button onClick={handleToggleComments} class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
      {(post.comment_count > 0 && toggleText !== 'Hide comments') ? `View comments${post.comment_count > 5 ? ` (${post.comment_count})` : ''}` : toggleText}
    </button>
  </div>
)

const PostPage = ({ id }) => {

  const [post, setPost ] = useState({});
  const [imgUrl, setImgUrl ] = useState('');
  const [todayDate, setTodayDate ] = useState('');
  const [toggleText, setToggleText ] = useState("View comments");
  const [feedbackText, setFeedbackText ] = useState("Loading...");
  const [toggleComments, setToggleComments ] = useState(false);
  const [isArchived, setIsArchived ] = useState(false);
  
  useEffect(() => {

    const date = new Date();
    // Saturday, 22 October 2022
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

    const month = months[date.getMonth()];
    const day = days[date.getDay()];

    const dDate = `${day}, ${('0' + date.getDate()).slice(-2)} ${month} ${date.getFullYear()}`;
    setTodayDate(dDate);

    const fetchPost = async () => {

      try {

        let result;

        if (id === 'today') {
          result = await getTodayPostWithTimezones();
        } else {
          result = await getPost(id);
          setIsArchived(true);
        }
  
        if (result.status === 'success') {
          setPost(result.post);
          const url = `${process.env.BACKEND_BASE_URL}/static/${result.post.media_name}`;
          setImgUrl(url);
        } else {
          setFeedbackText(result.detail || 'No painting found.');
        }

      } catch (err) {
        setFeedbackText(err.messsage);
      }
  
    }
    
    fetchPost();

  }, [])

  const handleToggleComments = () => {
    setToggleComments(!toggleComments);
    !toggleComments ? setToggleText("Hide comments") : setToggleText("View comments");
  }

  return (
    <div className={`h-full p-4 ${toggleComments ? 'w-screen' : 'container mx-auto'}`}>
      
      {/* Show today date */}
      { id === 'today' && <h3 class="text-center py-4">{todayDate}</h3>}

      {
        Object.keys(post).length > 0 ? (
          <div class="flex flex-col xl:flex-row justify-center content-center">
            <div class={toggleComments && 'xl:w-2/5'}>
              
              <img className="mx-auto" src={imgUrl} style="max-height: 75vh"></img>
              
              <PostInfo post={post} toggleText={toggleText}/>

              {!isArchived
              ? <CommentsButton post={post} handleToggleComments={handleToggleComments} toggleText={toggleText} />
              : post.comment_count !== 0 && (
                <CommentsButton post={post} handleToggleComments={handleToggleComments} toggleText={toggleText} />
              )
              }
              
            </div>
            {toggleComments && <CommentPage post={post} toggleComments={toggleComments} isArchived={isArchived} />}
        </div>
        ) : (
          <p class="text-center">{feedbackText}</p>
        )
      }
    </div>
  )
}

export default PostPage;
