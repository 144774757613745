import { h } from 'preact';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';

import { startResetPassword } from '../actions/authentication';

const ResetPasswordPage = (props) => {

  const resetPasswordToken = props.token;

  const [feedback, setFeedback] = useState({
    feedbackHeader: "",
    feedbackMessage: "" 
  });

  const resetPassword = async (event) => {

    try {

      event.preventDefault();
      const password = event.target.elements.newPassword.value.trim();
      const passwordConfirm = event.target.elements.newPasswordConfirm.value.trim();

      setFeedback(prevState => ({
        ...prevState,
        feedbackHeader: '',
        feedbackMessage: ''
      }));

      const result = await startResetPassword(resetPasswordToken, password, passwordConfirm);
      
      if (result.status === 'success') {
        setFeedback(prevState => ({
          ...prevState,
          feedbackHeader: result.msg,
          feedbackMessage: 'You\'re gonna be redirected to the login page in a few seconds.'
        }));
        timeOutFeedback(true);
      } else {
        setFeedback(prevState => ({
          ...prevState,
          feedbackHeader: 'Error',
          feedbackMessage: result.msg || result.detail
        }));
        timeOutFeedback();
      }
    } catch (error) {
      setFeedback(prevState => ({
        ...prevState,
        feedbackHeader: 'Error',
        feedbackMessage: error.message
      }));
      timeOutFeedback();
    }    
  }

  const timeOutFeedback = (redirect = false) => {
    setTimeout(() => {

      setFeedback(prevState => ({
        ...prevState,
        feedbackHeader: '',
        feedbackMessage: ''
      }));

      if (redirect) {
        route('/auth', true);
      }
    }, 4000);
  }

  return (
    <div>

      <div className={`${feedback.feedbackHeader.length === 0 ? 'hidden' : ''} mx-auto w-full max-w-md ${feedback.feedbackHeader === 'Error' ? 'bg-pink-100 border-pink-600 text-pink-500' : 'bg-blue-100 border-blue-600 text-blue-500'} border-l-4 p-4 my-4`} role="alert">
        <p className="font-bold">{feedback.feedbackHeader}</p>
        <p>{feedback.feedbackMessage}</p>
      </div>

      <div className="mx-auto flex flex-col w-full max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
        <div className="self-center text-md font-light text-gray-600 sm:text-2xl dark:text-white">
          Choose a new password
        </div>
        <div className="mt-8">
          <form onSubmit={resetPassword} autoComplete="off">

            {/* Password */}
            <div className="flex flex-col mb-2">
              <div className="flex relative ">
                <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                  <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                    </path>
                  </svg>
                </span>
                <input type="password" name="newPassword" placeholder="New password" className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" />
              </div>
            </div>

            {/* Password Confirmation */}
            <div className="flex flex-col mb-6">
              <div className="flex relative ">
                <span className="rounded-l-md inline-flex items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                  <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                    </path>
                  </svg>
                </span>
                <input type="password" name="newPasswordConfirm" placeholder="Confirm new password" className="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent" />
              </div>
            </div>

            <div className="flex w-full">
              <button type="submit" className="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ">
                Reset Password
              </button>
            </div>

          </form>
        </div>
      </div>

    </div>
  );
}

export default ResetPasswordPage;
