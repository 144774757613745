import authHeaders from '../utils/authHeaders';

// Create a new Stripe Checkout Session and return the URL
export const createCheckoutSession = async (lookup_key, token) => {

  try {

    const url = `${process.env.API_URL}/payments/create-checkout-session`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(lookup_key)
    })

    const json = await response.json();

    return json;
    
  } catch (err) {
    return err.message;
  }

}

// Create a new Stripe Portal Session and return the URL after checkout (with checkout session id)
export const createPortalSessionAfterCheckout = async (session_id, token) => {

  try {

    const url = `${process.env.API_URL}/payments/create-portal-session-after-checkout`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(session_id)
    })

    const json = await response.json();

    return json;
    
  } catch (err) {
    return err.message;
  }

}

// Create a new Stripe Portal Session and return the URL
export const createPortalSession = async (token) => {

  try {

    const url = `${process.env.API_URL}/payments/create-portal-session`;

    const response = await fetch(url, {
      method: "get",
      ...authHeaders(token)
    })

    const json = await response.json();

    return json;
    
  } catch (err) {
    return err.message;
  }

}
