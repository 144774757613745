import { h } from 'preact';
import { useEffect, useReducer, useState } from 'preact/hooks';
import { route } from 'preact-router';

import AuthContext from '../context/AuthContext';
import authReducer from '../reducers/auth';
import { activeSession, startLogout } from '../actions/authentication';
import { getCurrentUser } from '../actions/users';
import { getLocalStorage, checkValidSession } from '../utils/localStorage';

const AuthProvider = (props) => {

  const [ stateReady, setStateReady ] = useState(false);
  const [ state, dispatch ] = useReducer(authReducer, {});

  // State persistence
  const checkActiveSession = async () => {

    // Retrieving state from the server
    if (!state.isLoggedIn) {
      let token = state.token;
      if (!token) {
        const localToken = getLocalStorage('token');
        if (localToken) {
          token = localToken;
        }
      }

      if (token) {
        try {
          const result = await getCurrentUser(token);
          if (result.type === 'ACTIVE') {
            dispatch(result);
          } else {
            logout();
          }
        } catch (err) {
          logout();
        }
      } else {
        logout();
      }
    }

    setStateReady(true);
  }

  const logout = async () => {
    const result = await startLogout();
    if (result.type === 'LOGOUT') {
      dispatch(result);
      route('/auth', true);
    }
  }

  useEffect(() => {
    checkActiveSession();
  }, []);

  return (
    stateReady && (
      <AuthContext.Provider value={{ state, dispatch }}>
        {props.children}
      </AuthContext.Provider>
    )
  )
}

// https://stackoverflow.com/questions/56816374/context-consumer-vs-usecontext-to-access-values-passed-by-context-provider/56816825#56816825
// const AuthConsumer = AuthContext.Consumer;

export default AuthProvider;
