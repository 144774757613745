import authHeaders from '../utils/authHeaders';

// Search a source
export const searchSource = async (inputs, token) => {

  try {

    const url = `${process.env.API_URL}/sources/search`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(inputs)
    })

    const json = await response.json();

    if (json.message == "Success") {
      return { status: 'success', data: json.data, results_count: json.results_count };
    } else {
      return { status: 'failure', message: 'No artwork was found. Try a different query.' };
    }

  } catch (err) {
    return err.message;
  }

}

// Bulk search sources
export const bulkSearchSources = async (inputs, token) => {

  try {

    const url = `${process.env.API_URL}/sources/bulk-search`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(inputs)
    })

    const json = await response.json();

    if (json.length > 0) {
      return { status: 'success', data: json };
    } else {
      return { status: 'failure', message: 'No artwork was found. Try a different query.' };
    }

  } catch (err) {
    return err.message;
  }

}

// Retrieve missing info on post
export const findMissingInfo = async (inputs, token) => {
  try {

    const url = `${process.env.API_URL}/sources/search-missing`;

    const response = await fetch(url, {
      method: "post",
      ...authHeaders(token),
      body: JSON.stringify(inputs)
    })

    const json = await response.json();

    if (json) {
      return { status: 'success', data: json };
    } else {
      return { status: 'failure', message: 'No artwork was found. Try a different query.' };
    }

  } catch (err) {
    return err.message;
  }
}


