import validateForm from '../utils/validateForm';
import authHeaders from '../utils/authHeaders';

// Check for active session
export const activeSession = async (token) => {

  try {

    const url = `${process.env.API_URL}/login/test-token`;
    const response = await fetch(url, {method: "post", ...authHeaders(token)});
    const json = await response.json();

    if (json) {
      return { ...active(json), token };
    } else {
      return { status: 'fail', msg: "No active session. Please log in." };
    }

  } catch (err) {
    return err.message;
  }
};

// Sign up
export const startSignup = async (username, email, password, passwordConfirm) => {

  try {

    const [formIsValid, message] = validateForm({ username, email, password, passwordConfirm });

    if (formIsValid) {

      const newUser = { email, password, username };

      const url = `${process.env.API_URL}/users/`;

      const response = await fetch(url, {
        method: "post", 
        headers: {"content-type": "application/json"},
        body: JSON.stringify(newUser)
      });

      const json = await response.json();

      if (json.id) {
        return signup(json);
      } else {
        return { status: 'fail', msg: json.detail };
      }

    } else {
      return { status: 'fail', msg: message };
    }
  } catch (err) {
    return err.message;
  }
};

// Login
export const startLogin = async (email, password) => {

  try {

    const [formIsValid, message] = validateForm({ email, password });

    if (formIsValid) {

      const url = `${process.env.API_URL}/login/access-token`;

      // OAuth2 specifies that the client must send a username and password fields as form data
      let formData = new FormData();
      formData.append('username', email);
      formData.append('password', password);

      const response = await fetch(url, {body: formData, method: "post"});
      const json = await response.json();
      const token = json.access_token;

      if (token) {
        const _url = `${process.env.API_URL}/users/me`;
        const _response = await fetch(_url, authHeaders(token));
        const _json = await _response.json();
        return { ...login(_json), token };

      } else {
        return { status: 'fail', msg: json.detail };
      }

    } else {
      return { status: 'fail', msg: message };
    }
  } catch (err) {
    return err.message;
  }
};

// Reset password - Send email
export const startResetPasswordEmail = async (email) => {

  try {

    const [formIsValid, message] = validateForm({ email });

    if (formIsValid) {

      const url = `${process.env.API_URL}/password-recovery/${email}`;

      const response = await fetch(url, {method: "post"});
      const json = await response.json();
      return json;

    } else {
      return { status: 'fail', msg: message };
    }
  } catch (err) {
    return err.message;
  }
};

// Reset password - Update password in database
export const startResetPassword = async (token, newPassword, newPasswordConfirm) => {

  try {

    const [formIsValid, message] = validateForm({ password: newPassword, passwordConfirm: newPasswordConfirm });

    if (formIsValid) {

      const newPasswordBody = { token, new_password: newPassword };

      const url = `${process.env.API_URL}/reset-password`;

      const response = await fetch(url, {
        method: "post", 
        headers: {"content-type": "application/json"},
        body: JSON.stringify(newPasswordBody)
      });

      const json = await response.json();
      return json;

    } else {
      return { status: 'fail', msg: message };
    }
  } catch (err) {
    return err.message;
  }
};

export const startLogout = () => {
  try {
    return logout();
  } catch (err) {
    return err.message;
  }
};

// Actions to change state
export const login = (user) => ({
  type: 'LOGIN',
  uid: user.id,
  email: user.email,
  username: user.username,
  isLoggedIn: true,
  isActive: user.is_active,
  isSuperuser: user.is_superuser,
  isSubscribed: user.is_subscribed,
  subscriptionType: user.subscription_type,
  isOptedIn: user.is_opted_in
});

export const signup = (user) => ({
  type: 'SIGNUP',
  uid: user.id,
  email: user.email,
  username: user.username,
  isLoggedIn: true,
  isActive: user.is_active,
  isSuperuser: user.is_superuser,
  isSubscribed: user.is_subscribed,
  subscriptionType: user.subscription_type,
  isOptedIn: user.is_opted_in
});

export const active = (user) => ({
  type: 'ACTIVE',
  uid: user.id,
  email: user.email,
  username: user.username,
  isLoggedIn: true,
  isActive: user.is_active,
  isSuperuser: user.is_superuser,
  isSubscribed: user.is_subscribed,
  subscriptionType: user.subscription_type,
  isOptedIn: user.is_opted_in
});

export const logout = () => ({
  type: 'LOGOUT'
});
