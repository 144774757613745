import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { Link } from 'preact-router';

import AuthContext from '../context/AuthContext';
import { createCheckoutSession } from '../actions/payments';

const ProductDisplay = ({ handleCreateCheckoutSession, showFeedback, message }) => (
  <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">

    {/* Alert */}
    {showFeedback && (
      <div className="mx-auto w-full bg-blue-100 border-blue-600 text-blue-500 border-l-4 p-4 my-4" role="alert">
        <p>
          You must log in or register before subscribing. Click <Link href="/auth" className="font-bold">here</Link>
        </p>
      </div>
    )}

    {/* Cancel Message */}
    {message && (
      <div className="mx-auto w-full bg-red-100 border-red-600 text-red-500 border-l-4 p-4 my-4" role="alert">
        <p>
          {message}
        </p>
      </div>
    )}

    {/* Product box */}
    <div class="pricing-box max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
      <div class="bg-white dark:bg-gray-800 px-6 py-8 lg:flex-shrink-1 lg:p-12">
        <h3 class="text-2xl font-extrabold text-gray-900 sm:text-3xl dark:text-white">
          Daily art 🖼️ straight into your inbox 📥
        </h3>
        <p class="my-4 text-base text-gray-500 dark:text-gray-200">
          Subscribe today to start receiving a new artwork every day directly into your email inbox.
        </p>
        {/* <div class="flex-1 border-t-2 border-gray-200"></div> */}
        <div class="mt-4">
          <div class="flex items-center justify-center">
            <img src="../assets/carousel.png" alt="carousel" />
          </div>
        </div>
      </div>
      <div class="py-8 px-6 text-center bg-gray-50 dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
        <p class="text-lg leading-6 font-bold text-gray-900 dark:text-white">
          Only
        </p>
        <div class="my-2">
          <div class="rounded-md shadow">
            <form onSubmit={handleCreateCheckoutSession}>
              {/* Add a hidden field with the lookup_key of your Price */}
              <input type="hidden" name="lookup_key" value="peerdiem-premium-monthly" />
              <button type="submit" class="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                $4.99 / month
              </button>
            </form>
          </div>
        </div>
        <p>or</p>
        <div class="mt-2">
          <div class="rounded-md shadow">
            <form onSubmit={handleCreateCheckoutSession}>
              {/* Add a hidden field with the lookup_key of your Price */}
              <input type="hidden" name="lookup_key" value="peerdiem-premium-yearly" />
              <button type="submit" class="py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                $39.99 / year
              </button>
            </form>
          </div>
        </div>
        <span class="mt-1 text-sm text-green-600 font-bold">+30% in savings</span>
      </div>
    </div>
  </div>
);

const SuccessDisplay = () => {
  return (
    <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">

      {/* Product box */}
      <div class="pricing-box max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
        <div class="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
          <h4 class="text-xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:leading-9">
            You are now subscribed to Peerdiem 🎉
          </h4>
          <div class="my-4 text-base leading-6 text-gray-500">
            Thank you for supporting the development of our website. We have lots of new ideas coming!<br />Follow the latest announcements <a href="https://twitter.com/aco_le" target="_blank" className="font-bold text-blue-500">here</a>
            <br /><br />
            You will now start receiving a new artwork every day directly into your email inbox.
            <br /><br />
            You can manage your subscription directly from your <Link href="/me" className="font-bold text-blue-500">account</Link>.
          </div>
          <div class="mt-6">
            <div class="flex items-center justify-center">
              <img src="../assets/carousel.png" alt="carousel" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PremiumPage = () => {

  // 
  const { state } = useContext(AuthContext);
  let [feedback, setFeedback] = useState('');

  // States related to Stripe
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    // http://localhost:3000/subscription?success=true
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
    }

    // http://localhost:3000/subscription?canceled=true
    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled");
    }
  }, []);


  // Stripe 1 - Create checkout session
  const handleCreateCheckoutSession = async (event) => {

    event.preventDefault();

    // Retrieve hidden lookup_key from submitted form (monthly or yearly)
    let lookup_key = event.target.children.lookup_key.value;

    // Check if user is logged in using context
    if (state.isLoggedIn) {
      try {
        const result = await createCheckoutSession(lookup_key, state.token);

        if (result.status === 'success') {
          window.open(result.checkout_session_url, '_blank');
        
        } else {
          console.log(result.message);
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      // Ask user to login or register
      setFeedback(true);
    }
  };

  if (!success && message === '') {
    return <ProductDisplay handleCreateCheckoutSession={handleCreateCheckoutSession} showFeedback={feedback} />;
  } else if (success) {
    return <SuccessDisplay />;
  } else {
    return <ProductDisplay handleCreateCheckoutSession={handleCreateCheckoutSession} showFeedback={feedback} message={message} />
  }
}

export default PremiumPage;
